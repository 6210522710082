"use client";

import { useLaunchStore } from "@/stores/launchStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { shallow } from "zustand/shallow";

const useLaunch = () => {
  const [
    launchGame,
    gameUrl,
    dialogOpen,
    setDialogOpen,
    setGameUrl,
    isIframe,
    setIsIframe,
  ] = useLaunchStore(
    (state) => [
      state.launchGame,
      state.gameUrl,
      state.dialogOpen,
      state.setDialogOpen,
      state.setGameUrl,
      state.isIframe,
      state.setIsIframe,
    ],
    shallow
  );
  const { data: promotion } = usePromotionHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  let bonusMode = "ทั่วไป";
  if (promotion != null) {
    bonusMode = promotion?.pro_type
      ? promotion?.pro_type == "3"
        ? "เครดิตฟรี"
        : "โปรทั่วไป"
      : "ทั่วไป";
  }

  const handleSuccess = (gameUrl: string) => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleError = (message: string) => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    enqueueSnackbar(message, { variant: "error" });
  };

  const handleLaunch = (
    gameId: string,
    isMobile: boolean,
    isIframe: boolean = true
  ) => {
    console.log("KUY", loading ? "Y" : "N");
    if (loading) {
      return;
    }

    setLoading(true);

    console.log("Launch", "xxx");
    launchGame({
      gameId,
      bonusMode,
      isMobile,
      onSuccess: handleSuccess,
      onError: handleError,
      isIframe,
    });
  };

  return {
    gameUrl,
    dialogOpen,
    setDialogOpen,
    setGameUrl,
    launchGame: handleLaunch,
    isIframe,
    setIsIframe,
  };
};

export default useLaunch;
